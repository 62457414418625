import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import GD from "../src/assets/images/icons/g-d.png";
import GL from "../src/assets/images/icons/g-l.png";
import G1 from "../src/assets/images/icons/g1.png";
import G2 from "../src/assets/images/icons/g2.png";
import G3 from "../src/assets/images/icons/g3.png";
import G4 from "../src/assets/images/icons/g4.png";
import G5 from "../src/assets/images/icons/g5.png";
import G6 from "../src/assets/images/icons/g6.png";
import axios from "./axios";
import HeaderInner from "./Components/HeaderInner";
import { useTranslation } from "react-i18next";
import SpaLoader from "./Components/Loaders/SpaLoader";
import ReactGA from "react-ga4";
import Title from "./new-components/title/Title";
import NewFooter from "./NewFooter";
import { API_URL } from "./constants/api";
import axioss from "axios";
import { ThemeContext } from "./context/ThemeContext";

function Home() {
	const { t, i18n } = useTranslation();
	const [roomNumber, setRoomNumber] = useState();
	const [color, setColor] = useState();
	const [bgColor, setbgColor] = useState();
	const [logo, setLogo] = useState();
	const [bG, setBG] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const [include_feedback, setinclude_feedback] = useState(true);
	const [guest_directory, setGuest_directory] = useState([]);
	const [homeData, setHomeData] = useState();
	const currentDate = new Date();
	const location = useLocation();

	const rawPath = location.pathname;
	const room_uuid = rawPath.split("/")[1];

	// Define arrays for days and months
	const daysOfWeek = [
		"SUNDAY",
		"Monday",
		"TUESDAY",
		"WEDNESDAY",
		"THURSDAY",
		"FRIDAY",
		"SATURDAY",
	];
	const months = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];

	const lang = localStorage.getItem("lang");
	const room_number = localStorage.getItem("room_number");
	useEffect(() => {
		const el = document.getElementById("scroll");
		if (el) {
			el.scrollIntoView();
		}

		window.scrollTo({ top: 0 });
	}, [location.pathname]);
	const navigate = useNavigate();
	useEffect(() => {
		const getData = async () => {
			setIsLoading(true);
			// axios.get(`/648ed77e-da78-4987-9b86-de515659fc2c`).then((res) => {
			axios
				.get(`/${room_uuid}/qr-code`, { headers: { lang: lang } })
				.then((res) => {
					if (res.data.status === false) {
						navigate(`/${room_uuid}/qr-scan`);
					} else {
						const data = res.data.data;
						setHomeData(data);
						setbgColor(data?.header_color);
						setColor(data?.color);
						setLogo(data?.logo);
						setinclude_feedback(data?.include_feedback);
						setBG(data?.side_menu_background_image);
						setIsLoading(false);
						const numbersArray = data.room_number
							.match(/\d+/g)
							.join("");
						let result;

						if (numbersArray.length > 2) {
							result = parseInt(numbersArray, 10);
						} else {
							result = parseInt(numbersArray, 10);
						}
						setRoomNumber(result);
					}
				});
		};
		getData();
	}, []);
	const dateMonth = homeData?.today.split(" ");

	useEffect(() => {
		const getGuestDirectory = async () => {
			// axios.get(`/648ed77e-da78-4987-9b86-de515659fc2c`).then((res) => {
			axios.get(`/${room_uuid}/guest-directory`).then((res) => {
				const data = res.data.data;
				setGuest_directory(data.guest_directory);
			});
		};
		getGuestDirectory();
	}, []);
	// id = "scroll";
	const has_extra_services = localStorage.getItem("has_extra_services");
	// const new_design = localStorage.getItem("new_design");
	// const theme = new_design == "true";
	const { theme } = useContext(ThemeContext);
	// <HeaderInner logo={logo} bgColor={bgColor} color={color} />
	const baseUrl = API_URL;
	const businessType = localStorage.getItem("businessType");

	const businessName = localStorage.getItem("business_name");

	return (
		<div className={theme ? "food h_theme" : ""}>
			{theme ? (
				<Title logo={logo} />
			) : (
				<HeaderInner logo={logo} bgColor={bgColor} color={color} />
			)}
			{isLoading ? (
				<SpaLoader />
			) : (
				<div
					className="home-main main-home landing-panel home_page "
					style={{
						paddingTop: !theme ? "70px" : "70px",

						backgroundImage: `url(${bG})`,
						backgroundSize: "cover",
						// height: "calc(100vh -130px)",
						// height: "95vh",
						// marginBottom: "-200px",
					}}
				>
					<div className="base-back">
						<div className="container">
							<div className="top-info">
								<div className="wrapper">
									<div className="wrapper_container">
										<div className="left">
											<div className="bluer">
												<div className="city">
													<img src={GL} alt="" />
													<p className="sub_title">
														{t("CITY OF")}
													</p>
												</div>
												<p className="main_title">
													{t(homeData?.city)}{" "}
													<img
														src={homeData?.flag}
														alt=""
													/>
												</p>
											</div>
										</div>
										<div className="right">
											<div className="bluer">
												<div className="weather">
													<div>
														<img
															className="last"
															src={
																homeData?.weather_icon
															}
															alt=""
														/>
														<p className="sub_title">
															{t("WEATHER")}{" "}
														</p>
													</div>
													<p className="main_title">
														{" "}
														{homeData?.temperature}
													</p>
												</div>

												<div className="info_">
													<div>
														<img
															className="last"
															src={GD}
															alt=""
														/>
														<p className="sub_title">
															{t(
																homeData?.day_name
															)}
														</p>
													</div>

													<p className="main_title">
														<span className="mr-1">
															{t(
																`${dateMonth[0]}`
															)}
														</span>
														<span>
															{dateMonth[1]}
														</span>
														<span>
															{dateMonth[2]}
														</span>
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="box_min_container">
							{businessType != "Hospital" && (
								<Link
									to={
										theme
											? `/${room_uuid}/cart`
											: `/${room_uuid}/Checkout`
									}
									className="box_ check_box"
									onClick={() => {
										ReactGA.event({
											category:
												"Click on Checkout in Home Menu page",
											action: "Click",
											value: ``,
										});
									}}
								>
									<img src={G1} alt="" />
									<p className="title_">
										{t("Cart Checkout")}
									</p>
									<p className="sub">{room_number}</p>
								</Link>
							)}
							<Link
								to={`/${room_uuid}/AboutUs`}
								className="box_"
								onClick={() => {
									ReactGA.event({
										category:
											"Click on About in Home Menu page",
										action: "Click",
										value: ``,
									});
								}}
							>
								<img src={G4} alt="" />
								<p className="title_">{t("About Us")} </p>
							</Link>
							<Link
								to={`/${room_uuid}/MyOrders`}
								className="box_"
								onClick={() => {
									ReactGA.event({
										category:
											"Click on My orders in Home Menu page",
										action: "Click",
										value: ``,
									});
								}}
							>
								<img src={G5} alt="" />
								<p className="title_">
									{businessType == "Hospital"
										? t("My Bookings")
										: t("Orders")}{" "}
								</p>
							</Link>
							{businessType != "Hospital" &&
							!businessName.includes("Mubadala") ? (
								has_extra_services != "true" &&
								include_feedback ? (
									<Link
										to={`/${room_uuid}/Feedback`}
										className="box_"
										onClick={() => {
											ReactGA.event({
												category:
													"Click on Send a Feedback in Home Menu page",
												action: "Click",
												value: ``,
											});
										}}
									>
										<img src={G3} alt="" />
										<p className="title_">
											{t(
												businessType == "Hotel"
													? "Hotel Review"
													: "Holiday Home Review"
											)}{" "}
										</p>
									</Link>
								) : null
							) : null}
							{!baseUrl.includes("sgp") && (
								<a
									className="box_"
									target="_blank"
									href="https://qiblafinder.withgoogle.com/"
									onClick={() => {
										ReactGA.event({
											category:
												"Click on Qibla Finder in Home Menu page",
											action: "Click",
											value: ``,
										});
									}}
								>
									<img src={G6} alt="" />
									<p className="title_">
										{t("Qibla Finder")}{" "}
									</p>
								</a>
							)}
							{guest_directory.length > 0 && (
								<Link
									to={`/${room_uuid}/GuestDirectory`}
									className="box_"
									onClick={() => {
										ReactGA.event({
											category:
												"Click on GuestDirectory in Home Menu page",
											action: "Click",
											value: ``,
										});
									}}
								>
									<img src={G2} alt="" />
									<p className="title_">
										{t("Guest Directory")}{" "}
									</p>
								</Link>
							)}
						</div>
					</div>
				</div>
			)}
			{theme && <NewFooter />}
		</div>
	);
}
export default Home;
